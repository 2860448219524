// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-privacy-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("C:\\TeamCity\\buildAgent\\work\\e32a9140e112dff4\\src\\pages\\terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

